.home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}

.home h1 {
  font-size: 40px;
  font-weight: bold;
  color: #D0E1F9;
  margin-bottom: 15px;
  margin-top: 5px;
}

.home p {
  font-size: 24px;
  font-weight: normal;
  color: #D0E1F9;
  margin-bottom: 12px;
}