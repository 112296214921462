
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.ContactMe form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background-color: #4d648d;
}

.contact-form label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.input-container input[type='text'],
.input-container input[type='email'],
.input-container input[type='tel'],
.input-container textarea {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 450px;
  color: #4d648d;
}

.ContactMe button[type='submit'] {
  background-color: #1e90ff;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 450px;
}

.contact-form input[type='submit']:hover {
  background-color: #007fff;
}

.ContactMe .error {
  border-color: #ff0000;
}

.ContactMe .error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

