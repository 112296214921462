.skill-tile {
    background-color: #283655;
    border-radius: 20px 20px 20px 20px ;
    padding: 10px 15px;
    display: inline-block;
    margin-right: 10px;
    line-height: 1;
    margin-bottom: 0.5em;
  }

  .job-page {
    line-height: 1.75;
  }